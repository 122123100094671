import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
	badge: {
		background: '#38AD58',
		padding: '3px 12px 5px 12px',
		borderRadius: '32px',
		fontWeight: 700,
		transform: 'rotate(355deg)',
		color: 'white',
		fontSize: 15,
	},
}))
