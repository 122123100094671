import { createSlice } from '@reduxjs/toolkit'
import { UserStateScheme } from '../types/userSchema'
import { userAuthApi } from '../../api/userAuthApi'
import { subscrApi } from '@/entities/Subscr/api/subscrApi'

const initialState: UserStateScheme = {
	inited: false,
	userToken: '',
	isAuth: false,
	/*tariff: null,*/
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addMatcher(userAuthApi.endpoints.initAuth.matchFulfilled, (state, { payload }) => {
				const { token } = payload.result

				state.inited = true
				state.userToken = token
				state.isAuth = true
			})
			.addMatcher(userAuthApi.endpoints.initAuth.matchRejected, (state) => {
				state.inited = true
			})
			.addMatcher(userAuthApi.endpoints.logoutUser.matchFulfilled, (state) => {
				state.userToken = ''
				state.isAuth = false
			})
		/*	.addMatcher(subscrApi.endpoints.getSubscrData.matchRejected, (state) => {
				state.tariff = null
			})
			.addMatcher(subscrApi.endpoints.getSubscrData.matchFulfilled, (state, { payload }) => {
				switch (payload.tarif_id) {
					case 1 || 4:
						state.tariff = 'start'
						break
					case 2 || 5:
						state.tariff = 'artist'
						break
					case 3 || 6:
						state.tariff = 'label'
						break
					default:
						state.tariff = null
				}
			})*/
	},
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
