export const getRouteMain = () => '/'
export const getRouteYtnt = () => '/ytnt/*'
export const getRouteProfile = () => '/profile'
export const getRouteReleasesList = () => '/releases'
export const getRouteStatistics = () => '/statistics'
export const getRouteBalance = () => '/balance'
export const getRouteFinancialAnalytics = () => '/financial-analytics'
export const getRouteHelp = () => '/help'
export const getRouteHelpTicket = () => '/help/ticket'
export const getRouteAddingReleaseStart = (releaseUID: string) => `/releases/start/${releaseUID}`
export const getRouteAddingReleaseStep1 = (releaseUID: string) => `/releases/step1/${releaseUID}`
export const getRouteAddingReleaseStep2 = (releaseUID: string) => `/releases/step2/${releaseUID}`
export const getRouteAddingReleaseStep3 = (releaseUID: string) => `/releases/step3/${releaseUID}`
export const getRouteAddingReleaseChoiceDate = (releaseUID: string) => `/releases/choice-date/${releaseUID}`
export const getRouteAddingReleaseFinish = (releaseUID: string) => `/releases/finish-publication/${releaseUID}`
export const getRouteDetailRelease = (releaseUID: string) => `/releases/detail/${releaseUID}`
export const getRouteMarket = () => '/market'

export const getRoutePromotion = () => '/promotion'
