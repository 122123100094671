import { Skeleton, Text } from '@mantine/core'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './BalanceCard.styles'
import { canWithdraw } from '../../model/consts/balanceConsts'
import { WithdrawalMoneyButton } from '@/features/withdrawalMoney'
import { useGetBalance } from '@/entities/Balance'
import { useGetSubscrData } from '@/entities/Subscr'

export const BalanceCard = memo((props) => {
	const { t } = useTranslation('balance')
	const { classes } = useStyles()
	const { data: balanceData, isLoading: isLoadingBalance } = useGetBalance()
	const { data: subscrData } = useGetSubscrData()

	const getMinimumAmount = (tarif_id?: number) => {
		if (tarif_id === 1 || tarif_id === 4) return 2000
		if (tarif_id === 2 || tarif_id === 5) return 1000
		if (tarif_id === 3 || tarif_id === 6) return 150
		return 2000
	}

	const minAmount = getMinimumAmount(subscrData?.tarif_id)

	if (isLoadingBalance) {
		return <Skeleton w={'100%'} h={80} />
	}

	if (!balanceData) return null

	const { balance, balance_print } = balanceData.result

	return (
		<>
			<Text mb={12}>Баланс:</Text>
			<Text className={classes.sum}>{balance_print} ₽</Text>
			{canWithdraw(balance, minAmount) ? (
				<Text className={classes.prompt} mb={16}>
					{t('Доступно к выводу')} {balance_print} ₽
				</Text>
			) : (
				<Text className={classes.prompt} mb={16}>
					{t('Минимальная сумма для вывода')} {minAmount} ₽
				</Text>
			)}
			<WithdrawalMoneyButton
				balanceData={balanceData?.result}
				isLoading={isLoadingBalance}
				minAmount={minAmount}
			/>
		</>
	)
})

BalanceCard.displayName = 'BalanceCard'
