import { TariffListResponse } from '../model/types/tariffSchema'
import { rtkApi } from '@/shared/api/rtkApi'

const tariffApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		getTariffList: build.query<TariffListResponse, void>({
			query: () => ({
				url: '/tarif/list',
			}),
			transformResponse: (response: TariffListResponse) => {
				for (let i = 0; i < response.result.length; i++) {
					response.result[i].description_arg = response.result[i].description
						.split('\n')
						.map((item) => item.replace('•', '').trim())
				}

				return response
			},
		}),
	}),
})

export const useGetTariffList = tariffApi.useGetTariffListQuery
