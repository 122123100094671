import { memo } from 'react'
import { isNotEmpty, useForm } from '@mantine/form'
import { Button, Center, Stack, Text, TextInput, Anchor } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UserFromNotRussia } from '../model/types/paymentIsNotRussiaSchema'
import { usePayIsNotRussia } from '../api/paymentIsNotRussiaApi'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'
import { getDurationSubscr, getIdSubscr } from '@/widgets/PaymentSubscription'
import {
	boostyLinkArtist,
	boostyLinkLabel,
	boostyLinkStart,
} from '@/features/paymentIsNotRussia/model/const/boostyLink'

export const PaymentIsNotRussiaForm = memo(() => {
	const { t } = useTranslation('payment')

	const idSubscr = useSelector(getIdSubscr)
	const durationSubscr = useSelector(getDurationSubscr)

	const navigate = useNavigate()
	const [payIsNotRussia, { isLoading }] = usePayIsNotRussia()

	const { openGlobalAlert } = useGlobalAlertModal()

	const form = useForm<UserFromNotRussia>({
		initialValues: {
			email: '',
			country: '',
			payment: '',
			phone: '',
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : t('Неверный email')),
			country: isNotEmpty(t('Укажите страну')),
			payment: isNotEmpty(t('Укажите желаемый способ оплаты')),
			phone: (value) => (/^[+]?[\d]+$/.test(value) ? null : t('Неверный номер телефона')),
		},
	})

	const handleSendForm = async (values: UserFromNotRussia) => {
		try {
			await payIsNotRussia(values).unwrap()
			openGlobalAlert({
				type: 'success',
				title: t('Поздравляем'),
				message: t('Ваша заявка оформлена и отправлена в тех.поддержку'),
			})
			close()
		} catch (e: any) {
			openGlobalAlert({
				type: 'error',
				title: t('Ошибка'),
				message: e.data.message || t('Неизвестная ошибка, пожалуйста обратитесь в тех.поддержку!'),
			})
		}
	}

	const renderLinkBlock = (name: string, to: string) => {
		window.open(to, '_blank')

		return (
			<>
				{name && <Center>{`Для оплаты тарифа ${name} не из РФ,`}</Center>}
				<Center mt={20}>
					<Anchor href={to} target={'_blank'}>
						перейдите по ссылке
					</Anchor>
				</Center>
			</>
		)
	}

	if (idSubscr) {
		switch (idSubscr) {
			case '1':
				return renderLinkBlock('Start', boostyLinkStart)
			case '2':
				return renderLinkBlock('Artist', boostyLinkArtist)
			case '3':
				return renderLinkBlock('Label', boostyLinkLabel)
			case '4':
				return renderLinkBlock('Start', boostyLinkStart)
			case '5':
				return renderLinkBlock('Artist', boostyLinkArtist)
			case '6':
				return renderLinkBlock('Label', boostyLinkLabel)
			default:
				return <div>Ошибка</div>
		}
	} else {
		return (
			<>
				<Text ta="justify" color="gray" mb="xl">
					{t(
						// eslint-disable-next-line max-len
						'Для того, чтобы мы могли предложить Вам самый удобный способ оплаты из Вашей страны, заполните небольшую форму и наши менеджеры свяжутся с Вами.',
					)}
				</Text>
				<form onSubmit={form.onSubmit((values) => handleSendForm(values))}>
					<Stack spacing="md">
						<TextInput
							withAsterisk
							label={t('Email')}
							placeholder={t('your@email.com')}
							{...form.getInputProps('email')}
						/>
						<TextInput
							withAsterisk
							label={t('Введите страну')}
							placeholder={t('США, Великобритания, Таиланд или др.')}
							{...form.getInputProps('country')}
						/>
						<TextInput
							withAsterisk
							label={t('Введите желаемый способ оплаты')}
							placeholder={t('Способ оплаты')}
							{...form.getInputProps('payment')}
						/>
						<TextInput
							withAsterisk
							label={t('Номер Telegram/WhatsApp')}
							placeholder={t('+6699999999')}
							{...form.getInputProps('phone')}
						/>

						<Button type="submit" mt="xl" loading={isLoading}>
							{t('Отправить')}
						</Button>
					</Stack>
				</form>
			</>
		)
	}
})

PaymentIsNotRussiaForm.displayName = 'PaymentIsNotRussiaForm'
