import { Navigate } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import {
	getRouteAddingReleaseFinish,
	getRouteAddingReleaseStart,
	getRouteAddingReleaseStep1,
	getRouteAddingReleaseStep2,
	getRouteAddingReleaseStep3,
	getRouteBalance,
	getRouteDetailRelease,
	getRouteHelp,
	getRouteHelpTicket,
	getRouteFinancialAnalytics,
	getRouteMain,
	getRouteProfile,
	getRouteReleasesList,
	getRouteStatistics,
	getRouteYtnt,
	getRouteMarket,
	getRoutePromotion,
	getRouteAddingReleaseChoiceDate,
} from '../../consts/router'
import { ProfilePage } from '@/pages/ProfilePage'
import { ReleaseListPage } from '@/pages/ReleaseListPage'
import { StatisticsPage } from '@/pages/StatisticsPage'
import { FinancialAnalyticsPage } from '@/pages/FinancialAnalyticsPage'
import { AddingReleaseStep3Page } from '@/pages/AddingReleaseStep3Page'
import { ReleaseDetailPage } from '@/pages/ReleaseDetailPage'
import { AddingReleaseFinishPage } from '@/pages/AddingReleaseFinishPage'
import { AddingReleaseStep1Page } from '@/pages/AddingReleaseStep1Page'
import { AddingReleaseStartPage } from '@/pages/AddingReleaseStartPage'
import { AddingReleaseStep2Page } from '@/pages/AddingReleaseStep2Page'
import { BalancePage } from '@/pages/BalancePage'
import { HelpTicketPage } from '@/pages/HelpTicketPage'
import { MarketPage } from '@/pages/MarketPage'
import { PromotionPage } from '@/pages/PromotionPage'
import App from '@/app/App'
import { AddingReleaseChoiceDatePage } from '@/pages/AddingReleaseChoiceDatePage'

export enum AppRoutes {
	MAIN = 'main',
	YTNT = 'ytnt',
	PROFILE = 'profile',
	RELEASE_LIST = 'release_list',
	STATISTICS = 'statistics',
	BALANCE = 'balance',
	FINANCIAL_ANALYTICS = 'financial_analytics',
	HELP = 'help',
	HELP_TICKET = 'ticket',
	ADDING_RELEASE_STEP1 = 'adding_release_step1',
	ADDING_RELEASE_STEP2 = 'adding_release_step2',
	ADDING_RELEASE_START = 'adding_release_start',
	ADDING_RELEASE_STEP3 = 'adding_release_step3',
	ADDING_RELEASE_CHOICE_DATE = 'adding_release_choice_date',
	ADDING_RELEASE_FINISH = 'adding_release_finish',
	DETAIL_RELEASE = 'detail_release',
	MARKET = 'market',
	PROMOTION = 'promotion',
}

export const RoutePath: Record<AppRoutes, string> = {
	[AppRoutes.MAIN]: getRouteMain(),
	[AppRoutes.PROFILE]: getRouteProfile(),
	[AppRoutes.RELEASE_LIST]: getRouteReleasesList(),
	[AppRoutes.STATISTICS]: getRouteStatistics(),
	[AppRoutes.BALANCE]: getRouteBalance(),
	[AppRoutes.FINANCIAL_ANALYTICS]: getRouteFinancialAnalytics(),
	[AppRoutes.HELP]: getRouteHelp(),
	[AppRoutes.HELP_TICKET]: getRouteHelpTicket(),
	[AppRoutes.ADDING_RELEASE_START]: getRouteAddingReleaseStart(':releaseUID?'),
	[AppRoutes.ADDING_RELEASE_STEP1]: getRouteAddingReleaseStep1(':releaseUID'),
	[AppRoutes.ADDING_RELEASE_STEP2]: getRouteAddingReleaseStep2(':releaseUID'),
	[AppRoutes.ADDING_RELEASE_STEP3]: getRouteAddingReleaseStep3(':releaseUID'),
	[AppRoutes.ADDING_RELEASE_CHOICE_DATE]: getRouteAddingReleaseChoiceDate(':releaseUID'),
	[AppRoutes.ADDING_RELEASE_FINISH]: getRouteAddingReleaseFinish(':releaseUID'),
	[AppRoutes.DETAIL_RELEASE]: getRouteDetailRelease(':releaseUID'),
	[AppRoutes.YTNT]: getRouteYtnt(),
	[AppRoutes.MARKET]: getRouteMarket(),
	[AppRoutes.PROMOTION]: getRoutePromotion(),
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
	[AppRoutes.MAIN]: {
		path: RoutePath[AppRoutes.MAIN],
		element: <Navigate to={RoutePath.profile} />,
	},
	[AppRoutes.YTNT]: {
		path: RoutePath[AppRoutes.YTNT],
		element: <Navigate to={RoutePath.profile} />,
	},
	[AppRoutes.PROFILE]: {
		path: RoutePath[AppRoutes.PROFILE],
		element: <ProfilePage />,
	},
	[AppRoutes.RELEASE_LIST]: {
		path: RoutePath[AppRoutes.RELEASE_LIST],
		element: <ReleaseListPage />,
	},
	[AppRoutes.STATISTICS]: {
		path: RoutePath[AppRoutes.STATISTICS],
		element: <StatisticsPage />,
	},
	[AppRoutes.BALANCE]: {
		path: RoutePath[AppRoutes.BALANCE],
		element: <BalancePage />,
	},
	[AppRoutes.FINANCIAL_ANALYTICS]: {
		path: RoutePath[AppRoutes.FINANCIAL_ANALYTICS],
		element: <FinancialAnalyticsPage />,
	},
	[AppRoutes.HELP]: {
		path: RoutePath[AppRoutes.HELP],
		element: <Navigate to={getRouteHelpTicket()} />,
	},
	[AppRoutes.HELP_TICKET]: {
		path: RoutePath[AppRoutes.HELP_TICKET],
		element: <HelpTicketPage />,
	},
	[AppRoutes.ADDING_RELEASE_STEP1]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_STEP1],
		element: <AddingReleaseStep1Page />,
	},
	[AppRoutes.ADDING_RELEASE_STEP2]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_STEP2],
		element: <AddingReleaseStep2Page />,
	},
	[AppRoutes.ADDING_RELEASE_START]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_START],
		element: <AddingReleaseStartPage />,
	},
	[AppRoutes.ADDING_RELEASE_STEP3]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_STEP3],
		element: <AddingReleaseChoiceDatePage />,
	},
	[AppRoutes.ADDING_RELEASE_CHOICE_DATE]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_CHOICE_DATE],
		element: <AddingReleaseChoiceDatePage />,
	},
	[AppRoutes.ADDING_RELEASE_FINISH]: {
		path: RoutePath[AppRoutes.ADDING_RELEASE_FINISH],
		element: <AddingReleaseFinishPage />,
	},
	[AppRoutes.DETAIL_RELEASE]: {
		path: RoutePath[AppRoutes.DETAIL_RELEASE],
		element: <ReleaseDetailPage />,
	},
	[AppRoutes.MARKET]: {
		path: RoutePath[AppRoutes.MARKET],
		element: <MarketPage />,
	},
	[AppRoutes.PROMOTION]: {
		path: RoutePath[AppRoutes.PROMOTION],
		element: <PromotionPage />,
	},
}
