import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Text } from '@mantine/core'
import { useStyles } from './TextDescriptionRight.styles'

interface TextDescriptionRightProps {
	className?: string
	leftText: string
	rightText?: ReactNode
}

export const TextDescriptionRight: FC<TextDescriptionRightProps> = (props) => {
	const { className, leftText, rightText } = props

	const { classes } = useStyles()

	return (
		<div className={classNames(classes.textDescriptionRight, {}, [className])}>
			<Text span mr={5} color="gray.7">
				{leftText}:
			</Text>
			{rightText && <Text span>{rightText}</Text>}
		</div>
	)
}
