import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel'
import { Box, LoadingOverlay, Skeleton, Title } from '@mantine/core'
import type { Embla } from '@mantine/carousel'
import { useTranslation } from 'react-i18next'
import { CardDisplay } from '../CardDisplay/CardDisplay'
import { useGetBankCards } from '../../api/paymentMethodsApi'
import { paymentMethodsActions } from '../../model/slices/paymentMethodsSlice'
import { getCardUid } from '../../model/selector/getCardUid/getCardUid'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'

interface CardCarouselProps {
	sliderSize?: string
	disabledCarousel?: boolean
}

export const CardCarousel: FC<CardCarouselProps> = memo((props) => {
	const { sliderSize, disabledCarousel } = props

	const { t } = useTranslation('payment')

	const cardUID = useSelector(getCardUid)

	const dispatch = useAppDispatch()

	const { data: bankCardsData, isLoading: isLoadingBankCards, isFetching: isFetchingBankCards } = useGetBankCards()

	const [embla, setEmbla] = useState<Embla | null>(null)

	useAnimationOffsetEffect(embla, 200)

	const handleSetCardUid = useCallback(
		(cardUID: string) => {
			dispatch(paymentMethodsActions.setCardUid(cardUID))
		},
		[dispatch],
	)

	useEffect(() => {
		if (bankCardsData?.result.list[0] && !disabledCarousel) handleSetCardUid(bankCardsData.result.list[0].guid)
		else handleSetCardUid('')
	}, [bankCardsData, disabledCarousel, handleSetCardUid])

	if (isLoadingBankCards) {
		return <Skeleton height={100} width={'100%'} />
	}
	if (!bankCardsData) return null

	const { list } = bankCardsData.result

	if (disabledCarousel) return null

	if (list.length === 0) return null

	return (
		<Box pos={'relative'}>
			<LoadingOverlay visible={isFetchingBankCards} loaderProps={{ variant: 'dots' }} />
			<Title order={2} mb="xl">
				{t('Банковские карты')}
			</Title>
			<Carousel
				getEmblaApi={setEmbla}
				mx="auto"
				slideSize={sliderSize}
				controlsOffset="xs"
				slideGap="md"
				withControls={list.length !== 0}
			>
				{list.map((card) => (
					<Carousel.Slide key={card.guid}>
						<CardDisplay
							guid={card.guid}
							cardNumber={card.name.replace(/.*(\*\* \d{4})$/, '$1')}
							selectedValue={cardUID}
							handleSetCardUid={handleSetCardUid}
						/>
					</Carousel.Slide>
				))}
				<Carousel.Slide>
					<CardDisplay
						guid={''}
						cardNumber={t('Новая карта')}
						selectedValue={cardUID}
						handleSetCardUid={handleSetCardUid}
					/>
				</Carousel.Slide>
			</Carousel>
		</Box>
	)
})

CardCarousel.displayName = 'CardCarousel'
